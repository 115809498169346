
.ui-simplified {
    max-width: 1366px;
    position: relative;
    display: flex;
    flex-direction: row;
    border: 2px solid var(--grey-100);
    border-radius: 5px;
}

.ui-simplified .w-image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.ui-simplified .image {
    height: 636px;
    width: 100%;
    background: var(--secondary-light);
    position: relative;
    overflow: hidden;
}

.ui-simplified .w-title {
    z-index: 5;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: self-end;
    padding-bottom: 40px;
}

.ui-simplified .title {
    margin: 0 auto;
    font-size: var(--font-size-heading2);
    line-height: 1.125;
    font-weight: bold;
    color: var(--common-white);
    text-align: center;
    width: 80%;
    z-index: 1;
}

.ui-simplified .w-join-us-form {
    width: 50%;
    display: flex;
    align-items: center;
    padding-bottom: 50px;
}

/* BP 1-2 */
@media only screen and (max-width: 767px) {
    .ui-simplified .w-image {
        display: none;
    }

    .ui-simplified .w-join-us-form {
        width: 100%;
        display: block;
    }
}

/* BP 5 */
@media only screen and (min-width: 1025px) {
    .ui-simplified .w-title {
        padding-bottom: 50px;
    }

    .ui-simplified .title {
        font-size: 5rem;
        line-height: 1.1;
    }
}